/* Global Styles */
/**
 * Author:    HeckDanNorth
 * Created:   09.03.2023
 * 
 * Class: style.css
 * 
 * Library: React / React-Photo-Gallery 
 * Description: Defines css styles for entire web app
 * 
 **/

 .image-gallery-container {
  width: 100%;
  max-width: 100px; /* Set a maximum width if desired */
  margin: auto;
}

/* Media query for tablets */
@media (min-width: 768px) {
  .image-gallery-container {
    max-width: 600px; /* Adjust the width for tablets */
  }
}

/* Media query for desktops */
@media (min-width: 1024px) {
  .image-gallery-container {
    max-width: 800px; /* Adjust the width for desktops */
  }
}



* {
  margin: 0;
  padding: 0;
  font-family: 'Consolas', monospace;
}

body {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #333;
  background-color: #272727;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin: 1rem 0;
  color: #2D2D2D;
  /*color: #fff;*/
  font-family: 'Consolas', monospace;
}

li{
  color: #656363;
}
p {
  margin: 1rem 0;
  color: #656363;
  font-family: 'Consolas', monospace;
}

ul {

  list-style: none;
  margin: 1rem 0;
  padding: 0;
  font-family: 'Consolas', monospace;
}

a {
  display: inline;
  color: #4666FF;
  text-decoration: none;
}

/* Resume Styles */

.resume {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #D2D2D2;
  border-radius: 10px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/

}

.profile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
}

.profile img {
  width: 250px;
  height: 250px;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 2rem;
  margin-left: 0rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 4px solid #4666FF;
}

.profile h1 {
  font-size: 1.9rem;
  margin-bottom: 0.5rem;
  color: #2D2D2D;
  font-family: 'Consolas', monospace;
}

.contact-info {
  margin-left: 17.7rem;
  margin-top: -7rem;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #656363;
  font-family: 'Consolas', monospace;
}

.objective {
  margin-bottom: 2rem;
}

.education p, .experience p, .skills p, .objective p, .summary p, .about p, .volunteer p{
  text-indent: 3ch;
}
.projects p {
  text-indent: 3ch;
}

.education, .experience, .skills, .objective, .summary, .about, .volunteer, .projects, 
.freelanceProjects, .collegeProjects {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.selfhostMiners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio-image {
  max-height: 350px;
  width: auto;
  height: auto;
  margin: 10px; /* Adjust margin as needed */
}

.school h3, .job h3, .skills h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-family: 'Consolas', monospace;
}

.degree, .grad-date, .job-date, .description, .role {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #656363;
  font-family: 'Consolas', monospace;
}

.toggle-mode {
  display: block;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #4666FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.toggle-mode:hover {
background-color: #2944C7;
}

.resume.dark-mode {
background-color: #333;
color: #f5f5f5;
}
.resume.dark-mode p {
color:#ccc
}
.resume.dark-mode h1 {
color: #fff;
}
.resume.dark-mode h2 {
color: #fff;
}
.resume.dark-mode h3 {
color: #fff;
}
.resume.dark-mode li {
color: #ccc;
}

.resume.dark-mode h1.pRoot {
  color: #16C60C;
}
.resume.dark-mode h1.pSiggle {
  color: #4666FF;
}
.resume.dark-mode h1.pColon {
  color: #F2F3F4;
}
.resume.dark-mode h1.pRest {
  color: #F2F3F4;
}

h1.pRoot {
  color: #16C60C;
}
h1.pSiggle {
  color: #4666FF;
}
h1.pColon {
  color: #F2F3F4;
}
h1.pRest {
  color: #F2F3F4;
}

.resume.dark-mode h2.pRoot {
  color: #16C60C;
}
.resume.dark-mode h2.pSiggle {
  color: #4666FF;
}
.resume.dark-mode h2.pColon {
  color: #F2F3F4;
}
.resume.dark-mode h2.pRest {
  color: #F2F3F4;
}

h2.pRoot {
  color: #16C60C;
}
h2.pSiggle {
  color: #4666FF;
}
h2.pColon {
  color: #F2F3F4;
}
h2.pRest {
  color: #F2F3F4;
}
nav {
  display: block;
  margin: 2rem 15rem 0;
  padding: 0.1rem 2rem;
  border: none;
  border-radius: 50px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #4666FF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 900px;
}

li.nb {
  margin: 0 20px;
}

a {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #00bfff;
}

.nb {
  font-family: 'Consolas', monospace;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
  justify-content: center;
	align-items: stretch;
  padding: 10px;
}
.justify-content {
  display:flex;
  justify-content: center;
}

.displayskills 
{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: auto;
  align-content: center;
}
.displayskills:after {
  display: block;
  margin: 50px;
  flex: 999 999 auto;
}
.displayskills h3 {
  display: flex;
  justify-content: center;
}
.displayskills div {
  padding: 33px;
}
.skillsbg {
  background-color: #4666FF;
  border-width: 5px;
  border-color: #415BD7;
  border-style: solid;
  border-radius: 10px;
  margin: 10px;
  color: white;
  box-shadow: 4px 3px 5px 1px rgba(0,0,0,0.21);
  -webkit-box-shadow: 4px 3px 5px 1px rgba(0,0,0,0.21);
  -moz-box-shadow: 4px 3px 5px 1px rgba(0,0,0,0.21);
}
.skillsbg li {
  color:#d7d7d7;
}

@media only screen and (max-width: 768px) {

  .profile h1 {
    font-size: 1.6rem;

  }
  .contact-info {
    margin-left: 0px;
    margin-top: 0px;
  }
  .skills h2 {
    font-size: 1.6rem;
  }
  nav {
    margin: 0rem auto 0;
    font-size: 2rem;
    padding: 0rem 1rem;
    border-radius: 30px;
    width:auto;
  }
  
  li.nb {
    margin: auto;
  }

  .nb {
    font-family: 'Consolas', monospace;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch;
    padding: 8px;
  }
  

}

.job {
position: relative;
}

.job:before {
content: '';
position: absolute;
top: 0;
left: -1rem;
height: 100%;
border-left: 4px
}  

.selfhostMiners 
{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}
